import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const TestCaseActions = () => {
  return (
    <LayoutComponent>
      <h3>More Test Case actions</h3>
      <p>
        <strong>Clone Test Cases</strong>
        <p>
          Cloning a test case keeps scripts intact and clears all test results,
          comments, attachments, and defects.
        </p>
        <ol>
          <li>
            Select any number of test cases you want to clone, then click on the
            Clone button.
          </li>
          <li>
            New test cases appear in the test-case list with "(CLONE)" in the
            title. You can click/open a test case to rename it.
          </li>
        </ol>
      </p>
      <br />
      <p>
        <strong>Archive Test Cases</strong>
        <p>
          Unused test cases can clog up the test library, so you can archive
          those you aren’t currently using to clean up the space. Archiving
          removes unwanted test cases from the test library without permanently
          deleting the testing data.
        </p>
        <div className="testimonials">
          <h3>Note</h3>
          <p>
            You can move archived test cases back into the active test library
            at any time.
          </p>
        </div>
        <ol>
          <li>
            To archive unused test cases, select the test cases you want to move
            to the archive, then click Archive.
          </li>
          <li>
            Confirm you want to archive when prompted, and the test cases move
            out of the test library and into the archive.
          </li>
          <li>
            You can access archived test cases by clicking Archived Test Cases
            above the table.
          </li>
        </ol>
        <strong>Unarchive Test Cases</strong>
        <ol>
          <li>
            Click Archived Test Cases while in the Tests view to access the
            archive, select the test cases you want to move to the test library,
            then click Unarchive.
          </li>
          <li>
            Confirm you want to unarchive when prompted, and the test cases move
            back into the test library.
          </li>
        </ol>
      </p>
    </LayoutComponent>
  )
}

export default TestCaseActions
